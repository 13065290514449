import React from 'react';
import 'tailwindcss/tailwind.css';

function ContentOne() {
  const isMobile = window.innerWidth <= window.innerHeight;
  return (
    <div className="text-lg text-left flex flex-col items-center">
      <div className={isMobile ? "text-center text-xl p-20" : "text-center font-bold text-[1.5vw] p-20"}>
        We are a technology company that offers Internet of Robotics solutions to Home, Office, Industry, and Education.
      </div>
      <div className={isMobile ? "flex flex-col justify-between w-full" : "flex flex-row justify-between w-full"}>
        <div className={isMobile ?"flex flex-row m-2 p-5 border border-black rounded-[2vw] text-justify-[1rem]" :"flex flex-col m-2 p-5 border border-black rounded-[2vw] text-justify-[1rem]"}>
          <b>
            <img src='https://i.postimg.cc/q7qM1WHw/lighthouse.webp' alt="Vision Icon" style={{width: '3rem', height:'auto'}} className="icon"/>
            Vision:
          </b>
          To begin with the educational robot market and expand to telepresence robots. From there, a complete home/office robotics solution to improve productivity is the vision for the company in the next 5 years.
        </div>
        <div className={isMobile ?"flex flex-row m-2 p-5 border border-black rounded-[2vw] text-justify-[1rem]" :"flex flex-col m-2 p-5 border border-black rounded-[2vw] text-justify-[1rem]"}>
          <b>
            <img src='https://i.postimg.cc/7LnhGqVB/target-1.webp' alt="Mission Icon" style={{width: '3rem', height:'auto'}} className="icon"/>
            Mission:
          </b>
          To be a globally known startup in Robotics Communication and Solutions Company by 2025.
        </div>
        <div className={isMobile ?"flex flex-row m-2 p-5 border border-black rounded-[2vw] text-justify-[1rem]" :"flex flex-col m-2 p-5 border border-black rounded-[2vw] text-justify-[1rem]"}>
          <b>
            <img src='https://i.postimg.cc/1XCvrgGJ/open-book.webp' alt="Story Icon" style={{width: '3rem', height:'auto'}} className="icon"/>
            Story:
          </b>
          The company started as a part of READY (Research Entrepreneurship And Development for You) program of IHFC (Innovation Hub for Cobotics, IIT Delhi). This program provides a unique platform to the young talent of the country to work with some of the best academic and research minds of the country in the domain of robotics and cobotics. IHFC provided financial support, mentorship, and guidance to enable the start of the company.
        </div>
      </div>
    </div>
  );
}

export default ContentOne;
