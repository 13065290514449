import GridComponent from "./gridcomponent"


export default function Events() {
    return(
        <div className="events" id='events' style={{backgroundColor: 'white', textAlign: 'center', fontSize:'1.5vw'}}>
            <div className="text-4xl font-bold p-5">Recent Event List</div>
            
         <GridComponent/>

        </div>
    )
}
