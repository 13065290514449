import ContentOne from "./ContentOne";
import 'tailwindcss/tailwind.css';

export default function About2() {
  const isMobile = window.innerWidth <= window.innerHeight;
  return (
    <div className="bg-white" id='about2'>
      <div className="bg-white text-black text-lg flex flex-col items-center p-8">
        <h2 className={isMobile ? "text-3xl font-bold text-center" : "text-5xl font-bold text-center"}>About Company</h2>
        <ContentOne />
        <div id="test1" className="flex justify-center items-center mt-4">
          <img src="https://i.postimg.cc/G3SbC1Vw/websiteillustration.png" alt="illust" className="w-2/5 h-auto" />
        </div>
      </div>
    </div>
  );
}
