import React, { useState, useEffect } from 'react';
function NavigationHeader() {
//   function scrollTo(id){
//     const about = document.getElementById(id);
//     about.scrollIntoView({behavior: 'smooth'});
// }
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hasText, setHasText] = useState(true);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < window.innerHeight);
    };

    const handleTextPresence = () => {
      setHasText(document.getElementsByClassName('Link').length > 0);
    };

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('load', handleTextPresence);

    handleWindowResize(); // Set initial isMobile value
    handleTextPresence(); // Set initial hasText value

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('load', handleTextPresence);
    };
  }, []);

  return (
    <div className={`navigation-header ${menuOpen ? 'menu-open' : ''}`}>
      <a className='Title' href='https://seianmai.tech'> <img src="https://i.postimg.cc/L8mT73Xh/LOGO-v2.webp" alt="SeiAnmai Technologies" className="logo-image" style={{width: isMobile ? '50vw' : '20vw', height: 'auto'}}/></a>
      {!isMobile && hasText && (
        <div className="menu-items">
          {/* <a className='Link' href="https://google.com"><div className='navbar-selection' >Use TOTO</div></a> */}
          <a className='Link' href='/products'><div className='navbar-selection'>Products</div></a>
          <a className='Link' href="/recent"><div className='navbar-selection'>Recents</div></a>
          <a className='Link' href="/aboutus"><div className='navbar-selection'>About Us</div></a>
          <a className='Link' href="/controlTOTO"><div className='navbar-selection'>Control TOTO</div></a>
          <a className='Link' href="/Contact"><div className='navbar-selection'>Contact</div></a>
        </div>
      )}
      {(!hasText || isMobile) && (
        <button className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      )}
      {menuOpen && (
        <div className="menu-items">
          {/* <a className='Link' href="https://google.com"><div className='navbar-selection' style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px', backgroundColor: '#555'}}>Use TOTO</div></a> */}
          <a className='Link' href="/products"><div className='navbar-selection' style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px', backgroundColor: '#555'}}>Products</div></a>
          <a className='Link' href="/recent"><div className='navbar-selection' style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px', backgroundColor: '#555'}}>Recents</div></a>
          <a className='Link' href="/aboutus"><div className='navbar-selection' style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px', backgroundColor: '#555'}}>About Us</div></a>
          <a className='Link' href="/controlTOTO"><div className='navbar-selection' style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px', backgroundColor: '#555'}}>Control TOTO</div></a>
          <a className='Link' href="/contact"><div className='navbar-selection' style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px', backgroundColor: '#555'}}>Contact</div></a>
          
        </div>
      )}
    </div>
  );
}

export default NavigationHeader;