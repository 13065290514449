export default function ControlTOTO() {
    return(
        <div className="controlTOTO" id='controlTOTO' style={{backgroundColor: 'white', textAlign: 'center', fontSize:'1.5vw'}}>
            
        <h1>Control TOTO</h1>
        <h2>Coming Soon!</h2>

        </div>
    )
}
