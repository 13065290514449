import AboutPeople from "./AboutPeople";
import AboutContrib from "./AboutContrib";
import "./Aboutus.css";

export default function Aboutus(){
    return (
        <div className="relative h-screen w-screen" id='aboutus' style={{backgroundColor: 'white', align: 'centre'}}>
                <div className="p-5 m-2">
                   <h1 className="text-3xl text-center m-5 font-bold">Our Team</h1>
                   <AboutPeople/>
                   <h1 className="text-3xl text-center m-5 font-bold">Contributors</h1>
                   <AboutContrib/>  
                </div>
        </div>
    );
}