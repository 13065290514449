import 'tailwindcss/tailwind.css';  // Make sure to import Tailwind CSS

export default function Contact() {
    return(
        <div className="bg-white text-center text-lg" id='contact'>
            <div className="font-bold text-left pl-10">Contact info</div>
            <div className="text-left pl-10">
                <p className="font-bold">Office Location: No.12/20, Vanniar 2nd Lane, Choolaimedu, Chennai, Tamil Nadu, 600094</p>
                <p>Contact Phone: (+91) 9600959969</p>
                <p>Contact Email: <a href="mailto:director@seianmai.tech" className="text-blue-500 underline">director@seianmai.tech</a></p>
            </div>
        </div>
    )
}
