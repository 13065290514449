import LazyVimeoPlayer from './LazyVimeoPlayer';
import Contact from './Contact';
import About2 from './About2';
import 'tailwindcss/tailwind.css';  // Import Tailwind CSS



  

export default function HomePage() {
    const isMobile = window.innerWidth <= window.innerHeight;
    const Gap = () => isMobile ? (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    )
    :
    (<div>
            <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
        </div>
    );
    return (
        <div className="relative h-screen w-screen bg-black">
            <div className="absolute top-0 left-0 w-screen opacity-60">
                <LazyVimeoPlayer videoLink={isMobile ? 'https://vimeo.com/830096278?share=copy' : 'https://vimeo.com/830096617?share=copy'} />
            </div>
            <div className="absolute top-0 left-0 z-0 hidden md:block">
            </div>
            <div className="flex flex-row flex-2">
                <div className="flex-1 relative top-1/5 z-10 text-white">
                    <div className="relative w-1/2 h-[8.4vw] overflow-hidden">
                    <div className="animated-container">
                    <div className="animated-text">
                                <div className="word1">See</div>
                                <div className="word2">Hear</div>
                                <div className="word3">Speak</div>
                                <div className="word3">Move</div>
                    </div>
                    </div>
                    </div>
                    <div className="ml-[10vw] text-[7vw] font-hairline font-medium">Through<br />TOTO</div>
                </div>
            </div>
            <button className={isMobile ? "absolute top-[25%] left-1/2 transform -translate-x-1/2 text-white bg-[#555555] rounded-[2vw] text-[8vw] font-light hover:bg-white hover:text-black hover:opacity-90" : "absolute bottom-[20%] left-1/2 transform -translate-x-1/2 text-white bg-[#555555] rounded-[2vw] text-[6vw] font-light hover:bg-white hover:text-black hover:opacity-90"}>
                <a href="/products" className="no-underline text-black">
                    <div>Learn more</div>
                </a>
            </button>
            <div>
                <Gap/>
            </div>
            <div>
                <About2 />
                <Contact />
            </div>
        </div>
    );
}
