import { FaInstagram, FaTwitter, FaGithub, FaLinkedin, FaGlobe } from 'react-icons/fa';

export default function Card({ person }) {
  return (
    <div className="flex flex-col items-center p-4 border rounded ">
      <img src={person.image} alt="Team" className="w-32 h-32 object-cover rounded-full mb-4" />
      <div className="font-bold text-lg mb-2">{person.name}</div>
      <div className="text-gray-700 text-center mb-4" style={{ lineHeight: '1.2' }}>{person.position}</div>
      <div className="flex space-x-2">
        {person.linkedin && <a href={person.linkedin} className="text-blue-600"><FaLinkedin /></a>}
        {person.github && <a href={person.github} className="text-gray-900"><FaGithub /></a>}
        {person.website && <a href={person.website} className="text-green-600"><FaGlobe /></a>}
        {person.instagram && <a href={person.instagram} className="text-pink-600"><FaInstagram /></a>}
        {person.twitter && <a href={person.twitter} className="text-blue-400"><FaTwitter /></a>}
      </div>
    </div>
  );
}