import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
  

import HomePage from './components/HomePage';
import NavigationHeader from './components/NavigationHeader';
import './components/NavigationHeader.css';
import './components/HomePage.css';
import './fonts/static/RobotoMono-Bold.ttf';
import './fonts/static/RobotoMono-Light.ttf';
import './fonts/static/RobotoMono-Thin.ttf';
import './fonts/static/RobotoMono-Medium.ttf';
import Contact from './components/Contact';
import Recent from './components/recent';
import Products from './components/Products';
import Aboutus from './components/Aboutus';
import ControlTOTO from './components/ControlTOTO'; 

function App() {
  return (

    <div style={{width: '100vw', fontFamily: 'Light'}}>
    <NavigationHeader/>

  

    <Router>
        <Routes>
        <Route path="/" element={<HomePage/>}/> 
        </Routes>
        </Router> 



          <Router>
            <Routes> 
          <Route path="/recent" element={<Recent/>}/>
      
        </Routes>
      </Router>

      <Router>
            <Routes> 
          <Route path="/products" element={<Products/>}/>
      
        </Routes>
      </Router>
 

      <Router>
            <Routes> 
          <Route path="/contact" element={<Contact/>}/>
      
        </Routes>
      </Router>
   

      <Router>
            <Routes> 
          <Route path="/aboutus" element={<Aboutus/>}/>
      
        </Routes>
      </Router>


      <Router>
            <Routes> 
          <Route path="/ControlTOTO" element={<ControlTOTO/>}/>
      
        </Routes>
      </Router>


  <hr/> 
 
 
  

    </div>
  );
}

export default App;

