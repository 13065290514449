import { useState } from "react";
import "tailwindcss/tailwind.css";

const Image1 = () => (
  <div className="flex items-center justify-center h-full transition-opacity duration-500 opacity-100">
    <img className="object-contain w-1/2" src="https://i.postimg.cc/fL7y6mkT/totopic.webp" alt="1" />
  </div>
);

const Image2 = () => (
  <div className="flex items-center justify-center h-full transition-opacity duration-500 opacity-100">
    <img className="object-contain w-1/2" src="https://i.postimg.cc/W4khwQvZ/totofrontview.webp" alt="2" />
  </div>
);

const Image3 = () => (
  <div className="flex items-center justify-center h-full transition-opacity duration-500 opacity-100">
    <img className="object-contain w-1/2" src="https://i.postimg.cc/SRyRqRzL/totosideview.webp" alt="3" />
  </div>
);

export default function Products() {
    const [imageDisplayed, setContent] = useState(<Image1 />);
    const [imagenum, setnum] = useState(2);
    const isMobile = window.innerWidth <= window.innerHeight;
    const handleClicknext = () => {
      const val = (imagenum + 1) % 3;
      setnum(val);
      switch (val) {
        case 0:
          setContent(<Image1 />);
          break;
        case 1:
          setContent(<Image2 />);
          break;
        case 2:
          setContent(<Image3 />);
          break;
        case -1:
          setContent(<Image3 />);
          break;
        case -2:
          setContent(<Image2 />);
          break;
        default:
          setContent(null);
      }
    };
    
    const handleClickprevious = () => {
      const val = (imagenum - 1) % 3;
      setnum(val);
      console.log(val);
      switch (val) {
        case 0:
          setContent(<Image1 />);
          break;
        case 1:
          setContent(<Image2 />);
          break;
        case 2:
          setContent(<Image3 />);
          break;
        case -1:
          setContent(<Image3 />);
          break;
        case -2:
          setContent(<Image2 />);
          break;
        default:
          setContent(null);
      }
    };

      const scrollToSpecs = () => {
        const element = document.getElementById('specifications');
        element.scrollIntoView({ behavior: 'smooth' });
    }


    return (
      <div className="relative h-screen w-screen">
          <div className="text-center font-bold text-5xl">Products</div>
          <div className="text-center font-bold text-3xl">TOTO Research Edition</div>
          <div className={isMobile ? "flex flex-col bg-white" : "flex bg-white"}>
              <div className="flex flex-1 flex-col bg-black border rounded-2xl border-gray-700 p-4 m-5">
                  <b className="pl-10 pt-3 text-white text-2xl">Media:</b>
                  <hr />
                  <div className="imagecontainer">
                      {imageDisplayed}
                  </div>
                  <div className="text-center">
                      <button className="bg-black text-white border-2 border-white rounded-full p-2 mx-2 cursor-pointer transition-colors duration-300 ease-in-out" id="previous" onClick={() => handleClickprevious()}>Previous</button>
                      <button className="bg-black text-white border-2 border-white rounded-full p-2 mx-2 cursor-pointer transition-colors duration-300 ease-in-out" id="next" onClick={() => handleClicknext()}>Next</button>
                  </div>
              </div>
              <div className="flex flex-1 flex-col bg-black border rounded-3xl border-gray-700 m-5 p-8 text-white text-2xl">
                  <b>Description:</b>
                  <hr />
                  Designed for education and research purposes, the Research Edition is an open-source version of TOTO. Off-the-shelf components which are easily replaceable. It allows extensive data logging and plotting for more accessible research in Human-Robot Interaction, TelePresence Systems, SLAM, Navigation, Swarm Robotics, and Assistive Technologies. Built with the Industry standard ROS and ROS2 to be readily integratable into your robotics software workflow for a seamless experience.
              </div>
          </div>
          <div className="flex justify-center items-center my-5 ">
              <button className="bg-black text-white text-3xl p-4 rounded-3xl" onClick={scrollToSpecs}><h2>Specifics</h2></button>
          </div>
          <div id='specifications'>
              <h1 className="pl-20 text-4xl">Specifications of the Robot</h1>
              <div className={isMobile ? "flex flex-col": "flex"}>
                  <div className="flex flex-col flex-1 px-5 py-5">
                      <div className="pr-10 pl-10 pt-10 pb-10 border border-gray-700 rounded-3xl mb-2">
                          <h3 className="flex items-center mb-2">
                              <img src="https://cdn-icons-png.flaticon.com/128/1085/1085199.png" alt="Icon" className="w-9" />
                              Dimensions and Speed
                          </h3>
                          <ul>
                              <li>Size: 400x400x430mm (LxWxH)</li>
                              <li>Navigation Speed: 0.3m/s</li>
                          </ul>
                      </div>
                      <div className="pr-10 pl-10 pt-10 pb-10 border border-gray-700 rounded-3xl mb-4">
                          <h3 className="flex items-center mb-2">
                              <img src="https://cdn-icons-png.flaticon.com/128/2540/2540142.png" alt="Icon" className="w-9" />
                              Sensors & Detection
                          </h3>
                          <ul>
                              <li>Camera: Pan and tilt system with Pi camera v2</li>
                              <li>Lidar: RPLidar A1M8 (for accurate mapping and obstacle detection)</li>
                              <li>Ledge Sensors: IR Sharp-based (for low-level obstacle detection and ledge avoidance)</li>
                          </ul>
                      </div>
                      <div className="pr-10 pl-10 pt-10 pb-10 border border-gray-700 rounded-3xl mb-4">
                          <h3 className="flex items-center mb-2">
                              <img src="https://cdn-icons-png.flaticon.com/128/795/795653.png" alt="Icon" className="w-9" />
                              Navigation & Docking
                          </h3>
                          <ul>
                              <li>Autonomous Navigation: Capable in indoor environments</li>
                              <li>Charging Dock: Autonomous docking with fast charging capability</li>
                          </ul>
                      </div>
                  </div>
                  <div className="flex flex-col flex-1 px-5 py-5">
                      <div className="pr-10 pl-10 pt-10 pb-10 border border-gray-700 rounded-3xl mb-4">
                          <h3 className="flex items-center mb-2">
                              <img src="https://cdn-icons-png.flaticon.com/128/8279/8279574.png" alt="Icon" className="w-9" />
                              User Interface & Customization
                          </h3>
                          <ul>
                              <li>Python Interface: For waypoints, mapping, teleoperation, logging, and plotting</li>
                              <li>Open Source: Fully customizable and modifiable</li>
                              <li>Mounting: Additional mounting points on top of the robot</li>
                              <li>Power Supply: Additional power supply connector for custom hardware</li>
                          </ul>
                      </div>
                      <div className="pr-10 pl-10 pt-10 pb-10 border border-gray-700 rounded-3xl mb-4">
                          <h3 className="flex items-center mb-2">
                              <img src="https://cdn-icons-png.flaticon.com/128/53/53128.png" alt="Icon" className="w-9" />
                              Performance Metrics
                          </h3>
                          <ul>
                              <li>Payload Capacity: Up to 6kg</li>
                              <li>Battery: 8Ah 22.2V 6S LiPo </li>
                              <li>Operational Duration: Up to 8 hours on a single charge</li>
                              <li>Charging: 0% to 100% in 1 hour</li>
                          </ul>
                      </div>
                      <div className="pr-10 pl-10 pt-10 pb-10 border border-gray-700 rounded-3xl mb-4">
                          <h3 className="flex items-center mb-2">
                              <img src="https://cdn-icons-png.flaticon.com/128/724/724933.png" alt="Icon" className="w-9" />
                              Download
                          </h3>
                          <ul>
                              <li>
                                  <a href="https://drive.google.com/uc?export=download&id=17m95ugQyiRqEAAPT7gFs0DQcpzYjbA51" download="Brochure.pdf" className="text-blue-500 underline">
                                      Brochure
                                  </a>
                              </li>
                              <li>
                                  <a href="https://drive.google.com/uc?export=download&id=1uCb49N9N7fQpBBRELp2owZLVRZStAutR" download="SpecSheet.pdf" className="text-blue-500 underline">
                                      Specs sheet
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
  }