import './AboutPeople.css';
import Card from './Card';
const people = [
  {
    name: 'Srikrishna S',
    linkedin: 'https://www.linkedin.com/in/srikrishna-sowrirajan-736954153/',
    github: 'https://github.com/srike27',
    website: 'https://srike27.github.io/',
    image: 'https://i.postimg.cc/d3JnG2gb/srikrishna.webp',
    position: 'Director'
  },
  {
    name: 'Adithya R',
    linkedin: 'https://www.linkedin.com/in/adithyar19',
    github: 'https://github.com/Adithya191101',
    instagram: 'https://www.instagram.com/adithya_.1?igsh=MW5ma28yMWR0ZXMyYg==',
    image: 'https://github.com/srike27/imagehost/blob/main/adithya.jpeg?raw=true',
    position: 'Robotics'

  },
  {
    name: 'Siddhant Sarkar',
    linkedin: 'https://www.linkedin.com/in/siddhant-sarkar-500000186?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    github: 'https://github.com/siddhantsarkaR',
    image: 'https://github.com/srike27/imagehost/blob/main/siddhant.jpeg?raw=true',
    position: 'Hardware Design'
  },
  {
    name: 'Jay Vala',
    linkedin: 'https://www.linkedin.com/in/jay-vala-a58a29201?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    github: 'https://github.com/jayvala5555',
    image: 'https://github.com/srike27/imagehost/blob/main/jayvala.jpg?raw=true',
    position: 'Robotics'
  },
]
export default function AboutPeople() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {people.map((person, index) => (
        <div key={index} className="grid-item">
          <Card key={index} person={person} />
        </div>
      ))}
    </div>
  );
}
