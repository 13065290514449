import './AboutPeople.css';
import Card from './Card';
const people = [
  {
    name: 'Amal Narang',
    linkedin: 'https://www.linkedin.com/in/amal-narang-8b2608236/',
    github: 'https://github.com/utopian-monkey',
    position: 'Software',
    image: 'https://raw.githubusercontent.com/srike27/imagehost/main/placeholder.jpg',
  },
  {
    name: 'SriVattsan',
    linkedin: 'https://linkedin.com/in/vattsan',
    github: 'https://github.com/Vattsan37',
    instagram: 'https://www.instagram.com/vattsan_167/',
    position:'Robotics',
    image: 'https://raw.githubusercontent.com/srike27/imagehost/main/vattsan.png'
  },
  {
    name: 'Pramukh Jain',
    linkedin: 'https://www.linkedin.com',
    github: 'https://github.com',
    position: 'Robotics',
    image: 'https://raw.githubusercontent.com/srike27/imagehost/main/placeholder.jpg'
  },
  {
    name: 'Sumukh Porwal',
    linkedin: 'https://www.linkedin.com/in/sumukh-porwal-6a1393186/',
    github: 'https://github.com/Sumukh18',
    instagram: 'https://www.instagram.com/porwal_sumukh/',
    image: 'https://i.postimg.cc/MK2f3ZTG/sumukh-1.webp',
    position: 'Robotics, Mechanical'
  },
  {
    name: 'Ashish Kumar',
    linkedin: 'https://www.linkedin.com/in/ashish-kumar-64986a233',
    github: 'https://github.com/113Ashish',
    instagram: 'https://www.instagram.com/_ashish.113/',
    image: 'https://raw.githubusercontent.com/srike27/imagehost/main/ashish.jpg',
    position: 'Robotics'
  },
  {
    name: 'Ananya Singh',
    linkedin: 'https://www.linkedin.com/in/ananya-singh-559933242/',
    github: 'https://github.com/xananya06',
    website: 'https://xananya06.github.io/Portfolio/',
    image: 'https://i.postimg.cc/fTCW7MSp/1655730510476.webp',
    position: 'Software'
  }
]
export default function AboutContrib() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {people.map((person, index) => (
        <div key={index} className="grid-item">
          <Card key={index} person={person} />
        </div>
      ))}
    </div>
  );
}
