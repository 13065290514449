// GridComponent.js
import React from 'react';
import './GridComponent.css';

// Updated data with provided events
const eventData = [
  {
    image: 'https://github.com/srike27/imagehost/blob/main/WhatsApp%20Image%202023-09-08%20at%2013.26.24.jpeg?raw=true',
    text: 'IIT Delhi Janmashtami event',
    date: new Date('2023-09-07')
  },
  {
    image: 'https://github.com/srike27/imagehost/blob/main/Screenshot%202023-09-29%20at%2013-34-25%20SeiAnmai%20Technologies%20Private%20Limited%20Company%20Page%20Admin%20LinkedIn.png?raw=true',
    text: 'Visit from Embassy of Germany to our office at IIT Delhi',
    date: new Date('2023-08-14')
  },
  {
    image: 'https://github.com/srike27/imagehost/blob/main/Screenshot%202023-09-29%20at%2013-34-37%20SeiAnmai%20Technologies%20Private%20Limited%20Company%20Page%20Admin%20LinkedIn.png?raw=true',
    text: 'Gave a lecture on Introduction to Mobile Robots to Students from UAE',
    date: new Date('2023-07-14')
  },
  {
    image: 'https://github.com/srike27/imagehost/blob/main/Screenshot%202023-09-29%20at%2013-34-45%20SeiAnmai%20Technologies%20Private%20Limited%20Company%20Page%20Admin%20LinkedIn.png?raw=true',
    text: 'Attended AIR 2023 IIT Ropar',
    date: new Date('2023-07-08')
  },
  {
    image: 'https://github.com/srike27/imagehost/blob/main/Screenshot%202023-09-29%20at%2013-35-06%20SeiAnmai%20Technologies%20Private%20Limited%20Company%20Page%20Admin%20LinkedIn.png?raw=true',
    text: 'at ABU Robocon 2023',
    date: new Date('2023-06-18')
  },
  {
    image: 'https://github.com/srike27/imagehost/blob/main/Screenshot%202023-09-29%20at%2013-35-20%20SeiAnmai%20Technologies%20Private%20Limited%20Company%20Page%20Admin%20LinkedIn.png?raw=true',
    text: 'Invited to National Technology Week Event to present TOTO',
    date: new Date('2023-05-12')
  },
  // ... more events if any
];

const GridComponent = () => {
  // Sort events by date in descending order (most recent first)
  const sortedEvents = eventData.sort((a, b) => b.date - a.date);
  const isMobile = window.innerWidth <= window.innerHeight;

  return (
    <div className={isMobile ? "grid grid-cols-1 gap-4 p-5" : "grid grid-cols-3 gap-4 p-5" }>
      {sortedEvents.map((event, index) => (
        <div key={index} className="flex flex-col items-center justify-center p-5 text-center border-2 border-black rounded-3xl">
          <img src={event.image} alt={event.text} className={isMobile ? "h-1/2" : "w-3/4 h-auto" }/>
          <p className={isMobile ? "text-3xl" : "mt-4" }>{event.text}</p>
          <p className={isMobile ? "text-3xl" : "mt-4" }>{event.date.toLocaleDateString()}</p>
        </div>
      ))}
    </div>
  );
};

export default GridComponent;
