import React, { useEffect, useRef, useState } from 'react';
import Vimeo from '@vimeo/player';
import mobileImage from '../assets/loading.jpeg';
import './LazyVimeoPlayer.css'; // Import the CSS file for styling

const LazyVimeoPlayer = ({ videoLink }) => {
  const playerRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(16 / 9); // Default aspect ratio of 16:9
  const observerRef = useRef(null);

  useEffect(() => {
    let observer;

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(playerRef.current);
    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (visible) {
      const player = new Vimeo(playerRef.current, {
        url: videoLink,
        autoplay: true,
        muted: true,
        loop: true,
        controls: false,
        playsinline: true,
      });

      player.ready().then(() => {
        // Obtain aspect ratio
        player.getVideoWidth().then((width) => {
          player.getVideoHeight().then((height) => {
            const newAspectRatio = width / height;
            setAspectRatio(newAspectRatio);
          });
        });
      });

      player.on('error', () => {
        setLoadError(true);
      });

      return () => {
        player.unload();
      };
    }
  }, [visible, videoLink]);

  const videoStyle = {
    paddingTop: `${(1 / aspectRatio) * 100}%`,
  };

  return (
    <div>
      {loadError ? (
        <img src={mobileImage} alt="Fallback" />
      ) : (
        <div className="video" style={videoStyle} ref={playerRef}></div>
      )}
    </div>
  );
};

export default LazyVimeoPlayer;